<template>
  <div class="flex flex-row justify-center items-center h-screen">
    <div class="flex flex-col justify-center items-center">
      <div class="w-full px-10">
        <Notification v-if="hasText" :text="text" />
        <div class="mb-12"><Logo h="h-12" /></div>
        <p class="mb-12">Saisissez votre adresse e-mail et mot de passe</p>
        <p class="bg-red-200 p-2 rounded-sm mb-6" v-if="isError">
          Les identifiants renseignés sont incorrect !
        </p>
        <el-form class="form" :rules="rules" ref="loginFrom" :model="login">
          <el-form-item label="Votre email" prop="email">
            <el-input style="width: 100%" type="email" v-model="login.email" />
          </el-form-item>
          <el-form-item label="Mot de passe" prop="password">
            <el-input type="password" v-model="login.password" show-password />
          </el-form-item>
          <div class="flex flex-col items-center font-light">
            <div class="w-full flex items-center justify-end">
              <el-button
                style="width: 100%"
                type="primary"
                :loading="loading"
                :disabled="loading"
                @click="handleLogin('loginFrom')"
              >
                Se connecter
              </el-button>
            </div>

            <a href="#" class="text-gray-500 text-md mt-4 hover:text-purple-400"
              >Mot de passe oublié</a
            >
            <router-link class="text-gray-500 text-md mt-2" to="/signup">
              Vous avez pas encore de compte
              <span class="text-purple-400 hover:text-purple-500"
                >inscrivez-vous</span
              >
            </router-link>
          </div>
        </el-form>
      </div>
    </div>
    <!--    <div class="w-2/3 text-white py-20 px-20 login">
      <div class="w-1/2 absolute top-1/3">
        <h5 class="text-6xl font-bold text-center">
          <span class="entreprise">FICOMMERCE</span> votre logiciel de gestion
          100% Africain
        </h5>
        <p class="mt-8 desc">
          Une solutions pour une gestion simplifiée de votre
          <strong>PME</strong> , gestion des achats,<br />
          des ventes, stocks , depenses et gestion de la trésorie.
        </p>
      </div>
    </div> -->
  </div>
</template>

<script>
import Logo from "@/components/Logo";
import Notification from "@/components/Notification";
export default {
  name: "Login",
  components: { Logo, Notification },
  data() {
    return {
      loading: false,
      hasText: false,
      text: null,
      isError: false,
      login: {
        email: "",
        password: "",
        deviceInfo: {
          device_id: 453454555,
          device_type: "Mac book pros",
        },
      },
      rules: {
        email: [
          {
            required: true,
            message: "Email est obligatoire",
            trigger: "change",
          },
        ],
        password: [
          {
            required: true,
            message: "Le mot de passe est obligatoire",
          },
        ],
      },
    };
  },
  methods: {
    handleLogin(loginFrom) {
      this.$refs[loginFrom].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$store
            .dispatch("user/login", this.login)
            .then(() => {
              setTimeout(() => {
                this.loading = false;
                this.$router.push({
                  path: "/",
                });
              }, 1000);
            })
            .catch((error) => {
              console.log(error);

              setTimeout(() => {
                this.isError = true;
                this.loading = false;
              }, 1000);
            });
        } else {
          console.log("hello");
        }
      });
    },
  },
  mounted() {
    const params = this.$route.params;

    if (params.userLoggedOut) {
      this.hasText = true;
      this.text = "Deconnexion avec succès!";
    } else if (params.userRecoveredAccount) {
      this.hasText = false;
      this.text = `A recovery email has been sent to ${params.email}`;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
.login {
  color: $primary;
  background-color: #f8f4f1;
}
.desc {
  color: $text-color;
}
.entreprise {
  color: $secondary;
}
</style>
