<template>
  <transition
    enter-active-class="animate__animated animate__fadeInDown "
    leave-active-class="animate__animated animate__fadeOutRight"
  >
    <div v-if="show" class="bubble">
      <div class="message">{{ text }}</div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Notification",
  props: {
    text: {
      type: String,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  mounted() {
    this.show = true;
    setTimeout(() => {
      this.show = false;
    }, 5 * 1000);
  },
};
</script>

<style lang="scss" scoped>
.bubble {
  border-radius: 6px;
  position: absolute;
  top: 8%;
  right: 40%;
  padding: 12px 25px;
  background-color: #3672f8;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.message {
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
