<template>
  <img :class="h" class="mt-4" src="@/assets/logo-ficommerce-text.svg" />
</template>

<script>
export default {
  name: "Logo",
  props: {
    h: String,
  },
};
</script>

<style lang="scss" scoped></style>
